import { useQueryClient } from '@tanstack/react-query'
import Push from 'push.js'
import { useCallback, useEffect, useState } from 'react'

import { APPLI_URL } from 'src/constants/constants'
import {
  ME_QUERY_KEY,
  ORDERS_QUERY_KEY,
  ORDERS_STATUS_QUERY_KEY,
  SPIS_WITH_STOCKS_QUERY_KEY,
  useMeQuery,
  useSellerProductInstanceQuery,
} from 'src/queries/seller'
import { deletePaginatedEntity, updatePaginatedEntity } from 'src/queries/utils'
import {
  OrdersStatus,
  SellerProductInstance,
  SellerProductInstanceWithStock,
  SellerUser,
  Stock,
} from 'src/types'
import getPusher, {
  DataFromActiveQuery,
  ORDER_CANCELLED_BY_BUYER,
  ORDER_CREATED,
  ORDER_ITEM_CANCELLED_BY_BUYER,
  PusherPrivateChannelType,
  PusherUnsubscribeFunction,
  REACT_SELLER_UPDATED,
  REACT_STOCK_BULK_UPDATE_ARRIVALS,
  REACT_STOCK_BULK_UPDATE_NEW_DAY,
  REACT_STOCK_UPDATED_SELLER,
  SELLER_PRODUCT_INSTANCE_DELETED,
  SELLER_PRODUCT_INSTANCE_UPDATED,
  getDataFromActiveQuery,
} from 'src/utils/pusher'

export interface PusherOrderCancelledPayload {
  orderId: Id
  companyName: string
  cancelledWithPendingItems: boolean
}

export interface PusherOrderCreatedPayload {
  id: Id
  companyName: string
}

const usePusherSubscriptions = () => {
  const queryClient = useQueryClient()
  const pusherPrivateChannel = getPusher().privateChannel
  const pusherPublicChannel = getPusher().publicChannel
  const { data: seller } = useMeQuery()
  const [spiDataToUpdate, setSpiDataToUpdate] =
    useState<DataFromActiveQuery<SellerProductInstanceWithStock> | null>(null)

  useSellerProductInstanceQuery({
    id: spiDataToUpdate?.entity?.id,
    queryOptions: {
      onSuccess: newSpi => {
        if (!!spiDataToUpdate) {
          const updatedSpis = updatePaginatedEntity({
            entity: {
              ...newSpi,
              stock: spiDataToUpdate.entity?.stock,
              units: spiDataToUpdate.entity?.units, //it requires the query param purchasing_for_buyer_company_id to know if the spi can be unpacked
            },
            entityPages: spiDataToUpdate.paginatedData,
          })

          if (updatedSpis) queryClient.setQueryData(spiDataToUpdate.queryKey, updatedSpis)
        }
      },
      onSettled: () => {
        setSpiDataToUpdate(null)
      },
      retry: false,
      staleTime: 0,
    },
  })

  const handlePusherSellerUpdated = useCallback(
    (sellerUser: SellerUser) => {
      const currentSeller = queryClient.getQueryData<SellerUser>([ME_QUERY_KEY, null])
      queryClient.setQueryData([ME_QUERY_KEY, null], { ...currentSeller, ...sellerUser })
    },
    [queryClient]
  )

  const handlePusherSellerProductInstanceUpdated = useCallback(
    (spi: SellerProductInstance) => {
      const result = getDataFromActiveQuery<SellerProductInstanceWithStock>({
        id: spi.id,
        queryClient,
        queryKey: SPIS_WITH_STOCKS_QUERY_KEY,
      })

      if (!!result?.entity) setSpiDataToUpdate(result)
    },
    [queryClient]
  )

  const handlePusherStockUpdated = useCallback(
    (stock: Stock) => {
      const result = getDataFromActiveQuery<SellerProductInstanceWithStock>({
        queryClient,
        queryKey: SPIS_WITH_STOCKS_QUERY_KEY,
      })

      if (!!result) {
        let spiToUpdate: SellerProductInstanceWithStock | undefined
        const pageLength = result.paginatedData.pages?.length ?? 0

        for (let i = 0; i < pageLength; i++) {
          spiToUpdate = result.paginatedData.pages[i].results.find(
            spi => spi.id === stock.spiId && spi.stock.locationId === stock.locationId
          )

          if (!!spiToUpdate) {
            const updatedSpis = updatePaginatedEntity({
              entity: { ...spiToUpdate, stock },
              entityPages: result.paginatedData,
            })

            if (updatedSpis) queryClient.setQueryData(result.queryKey, updatedSpis)

            break
          }
        }
      }
    },
    [queryClient]
  )

  const handlePusherBulkStockUpdate = useCallback(() => {
    queryClient.invalidateQueries({
      type: 'active',
      exact: false,
      queryKey: [SPIS_WITH_STOCKS_QUERY_KEY],
    })
  }, [queryClient])

  const handlePusherSellerProductInstanceDeleted = useCallback(
    (spi: SellerProductInstance) => {
      const result = getDataFromActiveQuery<SellerProductInstanceWithStock>({
        queryClient,
        queryKey: SPIS_WITH_STOCKS_QUERY_KEY,
      })

      if (!!result) {
        const updatedSpis = deletePaginatedEntity({
          entityId: spi.id,
          entityPages: result.paginatedData,
        })

        if (updatedSpis) queryClient.setQueryData(result.queryKey, updatedSpis)
      }
    },
    [queryClient]
  )

  const handlePusherOrderCreated = useCallback(
    (payload: PusherOrderCreatedPayload) => {
      queryClient.invalidateQueries({ queryKey: [ORDERS_QUERY_KEY] })

      if (Push.Permission.has()) {
        Push.create(gettext('Klarys - Order Created'), {
          body: interpolate(
            gettext('%(buyerCompanyName)s has created order n° %(orderId)s'),
            {
              buyerCompanyName: payload.companyName,
              orderId: payload.id.toString(),
            },
            true
          ),
          icon: '/favicon.ico',
          link: APPLI_URL.SELLER.ORDERS.BASE,
        })
      }
    },
    [queryClient]
  )
  const handlePusherOrderCancelled = useCallback(
    (payload: PusherOrderCancelledPayload) => {
      queryClient.invalidateQueries({ queryKey: [ORDERS_QUERY_KEY] })

      if (Push.Permission.has()) {
        Push.create(gettext('Klarys - Order Cancelled'), {
          body: interpolate(
            gettext('%(buyerCompanyName)s has cancelled order n° %(orderId)s'),
            {
              buyerCompanyName: payload.companyName,
              orderId: payload.orderId.toString(),
            },
            true
          ),
          icon: '/favicon.ico',
          link: APPLI_URL.SELLER.ORDERS.BASE,
        })
      }
    },
    [queryClient]
  )
  const handlePusherOrderItemCancelled = useCallback(
    (payload: PusherOrderCancelledPayload) => {
      queryClient.invalidateQueries({ queryKey: [ORDERS_QUERY_KEY] })

      if (Push.Permission.has()) {
        Push.create(gettext('Klarys - Order Item Cancelled'), {
          body: interpolate(
            gettext('%(buyerCompanyName)s has cancelled order item from order n° %(orderId)s'),
            {
              buyerCompanyName: payload.companyName,
              orderId: payload.orderId.toString(),
            },
            true
          ),
          icon: '/favicon.ico',
          link: APPLI_URL.SELLER.ORDERS.BASE,
        })
      }
    },
    [queryClient]
  )

  useEffect(() => {
    const unSubscribePublicChannel = pusherPublicChannel.subscribe({
      [REACT_STOCK_BULK_UPDATE_ARRIVALS]: handlePusherBulkStockUpdate,
      [REACT_STOCK_BULK_UPDATE_NEW_DAY]: handlePusherBulkStockUpdate,
    })

    return () => unSubscribePublicChannel?.()
  }, [handlePusherBulkStockUpdate])

  useEffect(() => {
    let unSubscribePrivateCompanyChannel: PusherUnsubscribeFunction | undefined

    if (!!seller?.companyUuid) {
      unSubscribePrivateCompanyChannel = pusherPrivateChannel(
        seller.companyUuid,
        PusherPrivateChannelType.SELLER
      ).subscribe({
        [REACT_SELLER_UPDATED]: handlePusherSellerUpdated,
        [REACT_STOCK_UPDATED_SELLER]: handlePusherStockUpdated,
        [SELLER_PRODUCT_INSTANCE_UPDATED]: handlePusherSellerProductInstanceUpdated,
        [SELLER_PRODUCT_INSTANCE_DELETED]: handlePusherSellerProductInstanceDeleted,
        [ORDER_CREATED]: handlePusherOrderCreated,
        [ORDER_CANCELLED_BY_BUYER]: handlePusherOrderCancelled,
        [ORDER_ITEM_CANCELLED_BY_BUYER]: handlePusherOrderItemCancelled,
        'react-seller-order-to-prepare-count-updated': (payload: OrdersStatus) =>
          queryClient.setQueryData([ORDERS_STATUS_QUERY_KEY, null], payload),
      })
    }

    return () => unSubscribePrivateCompanyChannel?.()
  }, [seller?.companyUuid])
}

export default usePusherSubscriptions
