import { OrdersStatus } from 'src/types'

import { useEntitiesQuery } from '../../../entity'

export const ORDERS_STATUS_ENDPOINT = 'seller/orders/status'
export const ORDERS_STATUS_QUERY_KEY = 'orders/status'

export const useOrdersStatusQuery = () =>
  useEntitiesQuery<OrdersStatus>({
    endpoint: ORDERS_STATUS_ENDPOINT,
    queryKey: ORDERS_STATUS_QUERY_KEY,
    queryOptions: {
      cacheTime: 0,
      staleTime: 0,
    },
  })
